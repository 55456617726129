.ck-editor__editable {
  min-height: 65vh;
  width: 100%;
}
.text-editor-wrapper {
  width: 100%;
}
iframe#webpack-dev-server-client-overlay {
  display: none;
}

.ck-content {
  padding: 0 20px !important; /* Điều chỉnh kích thước padding theo nhu cầu của bạn */
}
